.content-main1 {
  background: #ffffff;
  border: 1px solid #aeb0b5;
  box-sizing: border-box;
  border-radius: 0px 0px 8px 8px !important;
  border-top: none !important;
  max-width: 1046px;
}

.content-main-header {
  background: #70b8ff !important;
  background-clip: border-box;
  border-radius: 8px 8px 0px 0px !important;
  height: 40px !important;
  margin: 0px !important;
}

.content-main-header1 {
  border-radius: 8px 8px 0px 0px !important;
  height: 40px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px !important;
  color: #ffffff;
  padding-left: 20px !important;
}

.content-main-header2 {
  border-radius: 8px 8px 0px 0px !important;
  height: 40px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px !important;
  color: #ffffff;
  padding-left: 20px !important;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
}
.content-table-row {
  border: none !important;
}
.content-header {
  height: 40px !important;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  width: 1046px;
  display: block;
  color: #ffffff;
  border-top: none !important;
}
.content-header1 {
  width: 25% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: left;
  border-top: none !important;
  padding: 16px 12px 4px 20px !important;
}
.content-header2 {
  width: 25% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: left !important;
  padding: 16px 12px 4px 120px !important;

  border-top: none !important;
}
.content-header3 {
  width: 25% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: center !important;
  padding-bottom: 4px !important;
  border-top: none !important;
  padding-top: 16px !important;
}
.content-header-id {
  width: 7% !important;
  max-width: 348px !important;
  height: 21px;
  font-family: "poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #70b8ff;
  border-bottom: none !important;
  text-align: center !important;
  padding-bottom: 4px !important;
  border-top: none !important;
  padding-top: 16px !important;
}
.content-cell1 {
  border-top: none !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 20px !important;
}
.content-cell2 {
  border-top: none !important;
  text-align: left !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 120px !important;
}
.content-cell3 {
  border-top: none !important;
  text-align: center !important;
  vertical-align: middle !important;
  padding: 16px 12px 16px 12px !important;
}

.pagination-container-content {
  display: flex;
  height: 29px;
  list-style: none;
  width: 100%;
  justify-content: flex-end;
  padding-right: 30px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.approve-css {
  display: inline !important;
  float: left !important;
  border: none !important;
  background: none !important;
  padding: 0px !important;
  color: #455674 !important;
}
.reject-css {
  display: inline !important;
  margin-left: 40px !important;
  color: #ff6750 !important;
  border: none !important;
  background: none !important;
  padding: 0px !important;
}

.view-button-content {
  display: inline !important;
  float: left !important;
  border: none !important;
  background: none !important;
  padding: 0px !important;
}

.cell4 {
  display: inline-flex;
  border: none !important;
}
.content-edit {
  border: none;
  background-color: #ffffff;
  margin-right: 10px;
}

.approve-css:hover {
  transform: scale(1.2);
}
.content-edit:hover {
  transform: scale(1.2);
}
.reject-css:hover {
  transform: scale(1.2);
}

@media screen and (max-width:768px){
  .reject-css {
    margin-left: 12px !important;
  }
}